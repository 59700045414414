import { ColorServiceInterface } from './ColorServiceInterface';
import { convertRGBToHex } from 'services/ColorService/utils/convertRGBToHex/convertRGBToHex';

enum MODIFY_TYPE {
  DARKEN,
  LIGHTEN,
}

export default class ColorService implements ColorServiceInterface {
  darkenColor(color: string, percent: number): string {
    return this.#modify(MODIFY_TYPE.DARKEN, color, percent);
  }

  lightenColor(color: string, percent: number): string {
    return this.#modify(MODIFY_TYPE.LIGHTEN, color, percent);
  }

  #modify(type: MODIFY_TYPE, color: string, percent: number): string {
    let amt = Math.round(2.55 * percent);
    if (type === MODIFY_TYPE.DARKEN) {
      amt *= -1;
    }

    const num = parseInt(color.replace('#', ''), 16);
    const R = (num >> 16) + amt;
    const B = ((num >> 8) & 0x00ff) + amt;
    const G = (num & 0x0000ff) + amt;

    return convertRGBToHex(R, G, B);
  }
}
