import { ReactWebComponent } from 'components/ReactWebComponent';

import { Notification } from './Notification';

new ReactWebComponent(
  'web-notification',
  (props) => <Notification {...props}>{props.message}</Notification>,
  'dist/timecamp-react-notification-bundle.min',
  ['message'],
);
