import { cancelled, fork, put, select, takeLatest } from 'redux-saga/effects';
import { restApiClient, storageServiceFactory } from 'services';
import { ArchivedFilter, LoadingTasksStatus, TaskModelActions, TaskModelStateFields } from './types';
import {
  changeLoadingTasksStatus,
  checkTasksCohesion,
  expandTaskList,
  fetchMissingTasks,
  fetchTasksPickerSuccess,
  fetchTasksSuccess,
  updateNewTaskCreated,
  updateNewTaskError,
  updateNewTaskLoading,
} from './actions';
import { PartialPayloadAction } from 'types/reduxTypes';
import { getArchivedFilterArgument } from './utils';
import { selectReportData } from 'redux/models/ReportModel/selectors';
import { ReportModelStateFields } from 'redux/models/ReportModel/types';
import { selectTasks } from './selectors';
import { INewTaskBody, ReportEntityType } from 'types';
import { translate } from 'utils';
import { decodeHtmlEntities } from 'utils/StringExtensions';

export const TaskModelSagas = [
  function* () {
    yield fork(function* () {
      yield takeLatest(TaskModelActions.FETCH_TASKS, fetchTasks);
    });
    yield fork(function* () {
      yield takeLatest(
        TaskModelActions.FETCH_TASKS_SUCCESS,
        onFetchTasksSuccess,
      );
    });
    yield fork(function* () {
      yield takeLatest(
        TaskModelActions.CHECK_TASKS_COHESION,
        onCheckTasksCohesion,
      );
    });
    yield fork(function* () {
      yield takeLatest(
        TaskModelActions.FETCH_MISSING_TASKS,
        onFetchMissingTasks,
      );
    });
    yield fork(function* () {
      yield takeLatest(
        TaskModelActions.CREATE_NEW_TASK,
        createNewTask,
      );
    });
  },
];

export function* fetchTasks({
  payload,
}: PartialPayloadAction<{
  archivedFilter: ArchivedFilter;
  buildTree: boolean;
}>) {
  const {
    archivedFilter = ArchivedFilter.FILTER_ARCHIVED_NO_FILTERING,
    buildTree = false,
  } = payload;

  const context = 'reports';

  // TODO: refactor this to use the same cache key as the task picker
  const tasksCacheKeyPrefix = 'cache.tasks.';

  const taskPickerTasksKey: string =
    `${tasksCacheKeyPrefix}taskPicker.${context}.${archivedFilter}`;
  const taskPickerSyncTokenKey =
    `cache.syncToken.tasks.taskPicker.${context}.${archivedFilter}`;

  const controller = new AbortController();
  const { signal } = controller;

  try {
    yield put(changeLoadingTasksStatus(LoadingTasksStatus.IN_PROGRESS));

    const token = yield storageServiceFactory.getStorage().then((storage) => storage.getItem(taskPickerSyncTokenKey));

    let temp = yield storageServiceFactory.getStorage().then((storage) => storage.getItem(taskPickerTasksKey));

    if (temp === 'undefined' || temp === '[]' || !temp) {
      yield storageServiceFactory.getStorage().then((storage) => {
        storage.deleteItem(taskPickerSyncTokenKey);
      });

      yield storageServiceFactory.getStorage().then((storage) => {
        storage.deleteItem(taskPickerTasksKey);
      });
    }

    const result = yield restApiClient.tasks.fetchTasks(
      signal,
      context,
      token,
      getArchivedFilterArgument(archivedFilter),
      true,
      false,
    );

    if (token !== result.syncToken) {
      yield storageServiceFactory.getStorage().then((storage) => {
        storage.setItem(taskPickerSyncTokenKey, result.syncToken);
      });

      yield storageServiceFactory.getStorage().then((storage) => {
        storage.setItem(taskPickerTasksKey, result.tasks);
      });

      temp = result.tasks;
    }

    const tasksObject = {};

    const tasks = temp.map((task) => {
      tasksObject[task.task_id] = Object.assign(
        {},
        task,
        { name: decodeHtmlEntities(task.name) },
      );
      return task;
    });

    yield put(fetchTasksSuccess({ tasks: tasksObject }));

    if (!buildTree) {
      yield put(changeLoadingTasksStatus(LoadingTasksStatus.LOADED));
      return;
    }

    const formatTaskObject = (task: any) => ({
      id: task.task_id,
      name: task.name,
      parentId: task.parent_id,
      billable: !!task.billable,
      externalTaskId: task.external_task_id,
      archived: !!task.archived,
      children: {},
    });

    const tasksArray = [
      ...new Set(tasks
        .map(item => formatTaskObject(item))
        .sort((a: any, b: any) => a.name.localeCompare(b.name)),
      )];

    const buildTreeTasks = (items) => {
      const tree = [];
      const mappedArr = {};

      items.forEach((item) => {
        const { id } = item;
        // eslint-disable-next-line no-prototype-builtins
        if (!mappedArr.hasOwnProperty(id)) {
          mappedArr[id] = item;
          mappedArr[id].children = [];
        }
      });

      for (const id in mappedArr) {
        // eslint-disable-next-line no-prototype-builtins
        if (mappedArr.hasOwnProperty(id)) {
          const mappedElem = mappedArr[id];

          if (mappedElem.parentId) {
            const { parentId } = mappedElem;
            mappedArr[parentId]?.children.push(mappedElem);
          } else {
            tree.push(mappedElem);
          }
        }
      }

      return tree;
    };

    yield put(fetchTasksPickerSuccess({ tasks: buildTreeTasks(tasksArray) }));
    yield put(changeLoadingTasksStatus(LoadingTasksStatus.LOADED));
  } finally {
    if (yield cancelled()) {
      controller.abort();
    }
  }
}

export function* onFetchTasksSuccess() {
  window.dispatchEvent(new Event('tasksLoaded'));
  yield put(checkTasksCohesion());
}

export function* onCheckTasksCohesion() {
  const report = yield select(selectReportData, {
    field: ReportModelStateFields.REPORT_SUMMARY,
  });

  const taskLoadingStatus = yield select(selectTasks, {
    field: TaskModelStateFields.TASKS_LOADING_STATUS,
  });

  if (taskLoadingStatus !== LoadingTasksStatus.LOADED) {
    return;
  }

  const allTaskData = yield select(selectTasks, {
    field: TaskModelStateFields.TASKS,
  });

  const missingTasks = [];

  const iterator = (element) => {
    if (
      element.type === ReportEntityType.TASK &&
      !Object.prototype.hasOwnProperty.call(allTaskData, element.id) &&
      element.id !== 0
    ) {
      missingTasks.push(element.id);
    }
    element.children.forEach(iterator);
  };

  report.data.forEach(iterator);

  if (missingTasks.length > 0) {
    yield put(changeLoadingTasksStatus(LoadingTasksStatus.IN_PROGRESS));

    yield put(fetchMissingTasks(missingTasks));
  }
}

export function* onFetchMissingTasks({
  payload,
}: PartialPayloadAction<{
  taskIds: number[];
}>) {
  const { taskIds } = payload;

  const controller = new AbortController();
  const { signal } = controller;

  const result = yield restApiClient.tasks.fetchSpecificTasks(signal, taskIds);

  if (result) {
    yield put(expandTaskList([result]));
  }

  yield put(changeLoadingTasksStatus(LoadingTasksStatus.LOADED));

  window.dispatchEvent(new Event('tasksLoaded'));
}

/**
 * { name: 'Task name' } - Create only task
 * { name: 'Task name', parent_id: 'Parent task name' } - Create a parent project with a new task
 * { name: 'Task name', parent_id: 1898 } - Create a new task with an existing parent project
 */
export function* createNewTask(data: PartialPayloadAction<INewTaskBody>) {
  const { payload } = data;
  const { parent_id, name } = payload;

  /**
   * Status updates
   */
  yield put(updateNewTaskLoading(true));
  yield put(updateNewTaskError(''));

  /**
   * Create a new task when user enters a project name in the input data
   */
  if (typeof parent_id === 'string') {
    try {
      /**
       * Create a new parent project
       */
      const parentTask = yield restApiClient.tasks.createNewTask({ body: { name: parent_id } });
      const [{ task_id: taskIdParent }] = Object.values(parentTask) as any;

      /**
       * The parent project was created correctly
       */
      if (typeof parseInt(taskIdParent) === 'number') {
        try {
          const newTaskWithParent = yield restApiClient.tasks.createNewTask({
            body: Object.assign(
              {},
              {
                name,
                parent_id: parseInt(taskIdParent),
              },
            ),
          });

          yield put(fetchTasksSuccess({ tasks: Object.assign({}, newTaskWithParent, parentTask) }));
          yield put(updateNewTaskCreated({ task: Object.values(newTaskWithParent)[0], type: 'add' }));
        } catch (error) {
          yield put(updateNewTaskLoading(false));
          yield put(updateNewTaskError(translate('TaskPicker.error_task_not_created')));
          throw new Error(translate('TaskPicker.error_task_not_created'));
        }
      } else {
        /**
         * The parent project was not created correctly
         */
        yield put(updateNewTaskLoading(false));
        yield put(updateNewTaskError(translate('TaskPicker.error_project_not_created')));
        throw new Error(translate('TaskPicker.error_project_not_created'));
      }
    } catch (error) {
      yield put(updateNewTaskLoading(false));
      yield put(updateNewTaskError(translate('TaskPicker.error_parent_not_exist')));
      throw new Error(translate('TaskPicker.error_parent_not_exist'));
    }
  }

  /**
   * Create a new task without a new paraent project
   */
  const newTask = yield restApiClient.tasks.createNewTask({ body: payload });
  yield put(fetchTasksSuccess({ tasks: newTask }));
  yield put(updateNewTaskCreated({ task: Object.values(newTask)[0], type: 'add' }));

  yield put(updateNewTaskError(''));
  yield put(updateNewTaskLoading(false));
}
