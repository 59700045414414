import { fromJS } from 'immutable';
import get from 'lodash/get';
import { Reducer } from 'redux';
import { PayloadAction } from 'types/reduxTypes';
import { ApprovalModelActions, ApprovalModelState, ApprovalModelStateFields } from './types';

const initialState: ApprovalModelState = fromJS({
  [ApprovalModelStateFields.APPROVALS]: {},
  [ApprovalModelStateFields.LOADING]: false,
});

const approvalModelReducer: Reducer<ApprovalModelState, PayloadAction> = (
  state: ApprovalModelState = initialState,
  action: PayloadAction = {} as PayloadAction,
): ApprovalModelState => {
  switch (action.type) {
    case ApprovalModelActions.FETCH_APPROVALS: {
      return state;
    }

    case ApprovalModelActions.FETCH_APPROVALS_SUCCESS: {
      const data = get(action, ['payload', 'approvals']);
      return state.setIn([ApprovalModelStateFields.APPROVALS], data);
    }

    case ApprovalModelActions.LOADING_APPROVALS: {
      const data = get(action, ['payload']);
      return state.setIn([ApprovalModelStateFields.LOADING], data);
    }

    case ApprovalModelActions.UPDATE_APPROVALS: {
      const { approval } = get(action, ['payload']);

      const approvals = state.getIn([ApprovalModelStateFields.APPROVALS]) as any;
      const findIndex = approvals.findIndex((item) => item.id === approval.id);
      if (findIndex !== -1) approvals[findIndex] = approval;

      return state.setIn([ApprovalModelStateFields.APPROVALS], approvals);
    }

    default: {
      return state;
    }
  }
};

export default approvalModelReducer;
