import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';
import {
  IApiClientStorageDownloadRequest,
  IApiClientStorageDownloadResponse,
} from 'services/RestApiClientFactory/modules/ApiClientStorage/ApiClientStorage.types';

export class ApiClientStorage extends ApiClientBase {
  async download({ id }: IApiClientStorageDownloadRequest) {
    return await this.fetchData<IApiClientStorageDownloadResponse>({
      queryString: `storage/download/${id}`,
      additionalHeaders: {
        Accept: '*/*',
      },
    });
  }
}
