import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';
import { Dict } from 'types';

export class ApiClientTimeTracking extends ApiClientBase {
  async fetchCanUserSelect() {
    const { data } = await this.fetchData<Dict<string>>({
      queryString: 'time_tracking/ajax_can_select_users',
      apiVersion: 0,
    });

    return data;
  }
}
