import { config, storageServiceFactory } from 'services/index';

export const getSummaryExpandStatuses = async () => {
  // TODO: Move KEY variable outside this function, but currently config variable return there undefined
  const KEY = config && `${config.rootGroupId}_${config.userId}_SUMMARY_REPORT_EXPANDED_IDS`;

  return await storageServiceFactory.getStorage().then((storage) => storage.getItem<Array<string>>(KEY));
};

export const setSummaryExpandStatuses = async (data) => {
  // TODO: Move KEY variable outside this function, but currently config variable return there undefined
  const KEY = config && `${config.rootGroupId}_${config.userId}_SUMMARY_REPORT_EXPANDED_IDS`;

  return await storageServiceFactory.getStorage().then((storage) => storage.setItem<Array<string>>(KEY, data));
};
