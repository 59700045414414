import BrowserStorageServiceInterface from './interface';
import { Maybe } from 'types';

export default class SafeSessionStorageService
implements BrowserStorageServiceInterface {
  getItem<T>(key: string): Promise<Maybe<T>> {
    return new Promise((resolve) => {
      // eslint-disable-next-line
      // @ts-ignore
      resolve(window.SafeSessionStorage.getItem(key));
    });
  }

  setItem<T>(key: string, item: T): Promise<Maybe<T>> {
    return new Promise((resolve) => {
      // eslint-disable-next-line
      // @ts-ignore
      resolve(window.SafeSessionStorage.setItem(key, item));
    });
  }

  deleteItem(key: string): Promise<Maybe<Event>> {
    return new Promise((resolve) => {
      // eslint-disable-next-line
      //@ts-ignore
      resolve(window.SafeSessionStorage.removeItem(key));
    });
  }
}
