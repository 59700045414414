import { DateFormatDict } from 'redux/models/SettingModel/types';
import { DateFormatterInterface } from './DateFormatterInterface';

export default class DateFormatter implements DateFormatterInterface {
  formatDate(date: Date, format: DateFormatDict): string {
    const day = this.#padLeft(date.getDate());
    const month = this.#padLeft(date.getMonth() + 1);
    const year = date.getFullYear();
    switch (format) {
      case DateFormatDict.FORMAT_YMD:
        return `${year}-${month}-${day}`;

      case DateFormatDict.FORMAT_MDY:
        return `${month}-${day}-${year}`;

      case DateFormatDict.FORMAT_DMY:
        return `${day}-${month}-${year}`;

      default:
        console.warn(`DateFormatter: Unknown format: ${format}`);
        return '';
    }
  }

  #padLeft = (data) => data.toString().padStart(2, '0');
}
