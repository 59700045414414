import { fromJS } from 'immutable';
import get from 'lodash/get';
import { Reducer } from 'redux';
import { LoadingTasksStatus, TaskModelActions, TaskModelState, TaskModelStateFields } from './types';
import { PayloadAction } from 'types/reduxTypes';

const initialState: TaskModelState = fromJS({})
  .setIn([TaskModelStateFields.TASKS], {})
  .setIn([TaskModelStateFields.TASKS_PICKER], [])
  .setIn([TaskModelStateFields.CREATE_NEW_TASK_LOADING], false)
  .setIn([TaskModelStateFields.CREATE_NEW_TASK_ERROR], '')
  .setIn([TaskModelStateFields.TASKS_LOADING_STATUS], LoadingTasksStatus.IDLE)
  .setIn([TaskModelStateFields.NEW_TASK_CREATED], []);

const taskModelReducer: Reducer<TaskModelState, PayloadAction> = (
  state: TaskModelState = initialState,
  action: PayloadAction = {} as PayloadAction,
): TaskModelState => {
  switch (action.type) {
    case TaskModelActions.FETCH_TASKS: {
      return state;
    }

    case TaskModelActions.FETCH_TASKS_SUCCESS: {
      const data = get(action, ['payload', 'tasks']);
      return state
        .setIn([TaskModelStateFields.TASKS], data);
    }

    case TaskModelActions.FETCH_TASKS_PICKER_SUCCESS: {
      const data = get(action, ['payload', 'tasks']);

      return state
        .setIn([TaskModelStateFields.TASKS_PICKER], data);
    }

    case TaskModelActions.EXPAND_TASK_LIST: {
      const [data] = get(action, ['payload', 'tasks']);

      return state.updateIn([TaskModelStateFields.TASKS], (taskList: any) => ({ ...taskList, ...data }));
    }

    case TaskModelActions.CHANGE_LOADING_TASKS_STATUS: {
      const status = get(action, ['payload', 'status']);
      return state.setIn([TaskModelStateFields.TASKS_LOADING_STATUS], status);
    }

    case TaskModelActions.UPDATE_NEW_TASK_LOADING: {
      const status = get(action, ['payload']);
      return state.setIn([TaskModelStateFields.CREATE_NEW_TASK_LOADING], status);
    }

    case TaskModelActions.UPDATE_NEW_TASK_ERROR: {
      const error = get(action, ['payload']);
      return state.setIn([TaskModelStateFields.CREATE_NEW_TASK_ERROR], error);
    }

    case TaskModelActions.UPDATE_NEW_TASK_CREATED: {
      const { task, type = 'add' } = get(action, ['payload']);
      return state.updateIn([TaskModelStateFields.NEW_TASK_CREATED], (state: any) => {
        if (type === 'add') {
          return [...state, task];
        }

        return state.filter((item) => item.task_id !== task.id);
      });
    }

    default: {
      return state;
    }
  }
};

export default taskModelReducer;
