import { ArchivedFilter } from 'redux/models/TaskModel/types';
import { DetailedSortColumnTypes } from 'types';
import { UsersStatusFilter } from 'redux/models/UserModel/types';
import { IGetExpensesParams } from 'services/RestApiClientFactory';
import {
  TaskSelectorResult,
} from 'components/common/TaskPicker/lib/utils/convertSelectedTasksToTasksSelector/selectTaskSelector.types';

export enum FilterModule {
  REPORTS = 'reports',
  ORDERS = 'orders',
  SIZING = 'sizing',
  PERMISSIONS = 'permissions',
  EXPANSES = 'expanses',
  USERS = 'users',
}

export enum OrdersName {
  SUMMARY_ORDER = 'summaryOrder',
  DETAILED_ORDER = 'detailedOrder',
}

export enum FilterName {
  TIME_FRAME = 'timeFrame',
  TIME_FRAME_STRING = 'timeframeString',
  GROUP_BY = 'group_by',
  ORDER = 'order_by',
  TAGS = 'tagIds',
  APPROVAL_STATUS = 'approvalStatus',
  TASKS = 'taskIds',
  USERS = 'userIds',
  BILLABLE = 'billable',
  NOTE = 'note',
  INVOICED = 'invoiced',
  ARCHIVED_TASKS = 'archived',
  STATUS_OF_DISPLAYED_USERS = 'statusOfDisplayedUsers',
  // todo - separate enum or sth
  EXPANSE_CATEGORIES = 'expenseCategoryIds',
  EXPANSE_START_DATE = 'timeFrameStart',
  EXPANSE_END_DATE = 'timeFrameEnd',
  EXPANSE_SORT_ORDER = 'sortOrder',
  EXPANSE_SORT_BY = 'sortBy',
  // For dynamic picker
  EXPANSE_TASK_PICKER_STATUS = 'taskPickerStatus',
  DETAILED_SIZE = 'detailedSize',
}

export const ExpensesFilters: FilterName[] = [
  FilterName.EXPANSE_END_DATE,
  FilterName.EXPANSE_START_DATE,
  FilterName.EXPANSE_CATEGORIES,
  FilterName.TASKS,
  FilterName.EXPANSE_SORT_BY,
  FilterName.EXPANSE_SORT_ORDER,
  FilterName.EXPANSE_TASK_PICKER_STATUS,
];

export const archivedDictionary: { [key: string]: boolean | null } = {
  [ArchivedFilter.FILTER_ARCHIVED_NO_FILTERING]: null,
  [ArchivedFilter.FILTER_ARCHIVED_ONLY_ARCHIVED_TASKS]: true,
  [ArchivedFilter.FILTER_ARCHIVED_ONLY_ACTIVE_TASKS]: false,
};

// timeframe
export enum TimeframeKeys {
  START_DATE = 'start',
  END_DATE = 'end',
  TODAY_DATE = 'Today',
  YESTERDAY_DATE = 'Yesterday',
}

// group by
export enum GroupByKeys {
  GROUP_1_LEVEL = 'firstGroup',
  GROUP_2_LEVEL = 'secondGroup',
}

export enum GroupByFilter {
  NONE,
  LEVEL_1_TASKS,
  LEVEL_2_TASKS,
  LEVEL_3_TASKS,
  LEVEL_4_TASKS,
  PEOPLE,
  TAGS,
  BILLABLE,
}

export const GroupByFilterDictionary: { [key in GroupByFilter]: any } = {
  [GroupByFilter.NONE]: 'none',
  [GroupByFilter.LEVEL_1_TASKS]: '1stLevel',
  [GroupByFilter.LEVEL_2_TASKS]: '2ndLevel',
  [GroupByFilter.LEVEL_3_TASKS]: '3rdLevel',
  [GroupByFilter.LEVEL_4_TASKS]: '4thLevel',
  [GroupByFilter.PEOPLE]: 'People',
  [GroupByFilter.TAGS]: 'Tag',
  [GroupByFilter.BILLABLE]: 'Billable',
};

// order by
export enum OrderByValues {
  ASC = 'asc',
  DESC = 'desc',
}

export enum FilterPermissions {
  CAN_USER_SELECT_PEOPLE = 'canUserSelectPeople'
}

export type ReportFiltersState = {
  [FilterModule.REPORTS]: {
    [FilterName.TIME_FRAME]: {
      [TimeframeKeys.START_DATE]: Date
      [TimeframeKeys.END_DATE]: Date;
    };
    [FilterName.TIME_FRAME_STRING]: string;
    [FilterName.GROUP_BY]: {
      [GroupByKeys.GROUP_1_LEVEL]: string;
    };
    [FilterName.TAGS]: number[];
    [FilterName.USERS]: Array<number>,
    [FilterName.NOTE]: string | null,
    [FilterName.TASKS]: Array<string> | TaskSelectorResult[],
    [FilterName.BILLABLE]: boolean,
    [FilterName.INVOICED]: boolean,
    [FilterName.ARCHIVED_TASKS]: ArchivedFilter,
  }
  [FilterModule.ORDERS]: {
    [OrdersName.DETAILED_ORDER]: [{
      id: DetailedSortColumnTypes.DURATION,
      desc: true,
    }],
    [OrdersName.SUMMARY_ORDER]: {
      column: DetailedSortColumnTypes,
      direction: OrderByValues,
    },
  },
  [FilterModule.PERMISSIONS]: {
    [FilterPermissions.CAN_USER_SELECT_PEOPLE]: boolean,
  },
  [FilterModule.EXPANSES]: {
    [FilterName.EXPANSE_SORT_ORDER]: IGetExpensesParams['sortOrder']
    [FilterName.EXPANSE_SORT_BY]: IGetExpensesParams['sortBy'],
    [FilterName.EXPANSE_TASK_PICKER_STATUS]: IGetExpensesParams['taskPickerStatus'],
  },
  [FilterModule.USERS]: {
    [FilterName.STATUS_OF_DISPLAYED_USERS]: UsersStatusFilter,
  },
  [FilterModule.SIZING]: {
    [FilterName.DETAILED_SIZE]: {
      [DetailedSortColumnTypes.TASK_NAME]: number,
      [DetailedSortColumnTypes.USER_NAME]: number,
      [DetailedSortColumnTypes.DURATION]: number,
      [DetailedSortColumnTypes.NOTE]: number,
      [DetailedSortColumnTypes.TIMESTAMP]: number,
      [DetailedSortColumnTypes.BILLABLE]: number,
    },
  }
};
