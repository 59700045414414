import ReduxWrappedComponent from 'components/ReduxWrappedComponent';

import { IReactWebComponentWrapperProps } from './ReactWebComponentWrapper.types';
import { useThemeContext } from 'contexts/ThemeContext';

export const ReactWebComponentWrapper = (props: IReactWebComponentWrapperProps) => (
  <ReduxWrappedComponent component={ReactWebComponentWrapperInner} props={props} />
);

const ReactWebComponentWrapperInner = ({ inner }: IReactWebComponentWrapperProps) => {
  const { theme } = useThemeContext();

  return (
    <div
      className={'web-component'}
      data-theme={theme === 'dark' ? 'darkmode' : 'default'}
    >
      {inner}
    </div>
  );
};
