import store from 'redux/store';
import { ReportEntityDataProviderInterface } from './ReportEntityDataProviderInterface';
import { ReportEntityType } from 'types';
import { selectTaskById, selectTasks } from 'redux/models/TaskModel/selectors';
import { TaskModelStateFields } from 'redux/models/TaskModel/types';
import { reportEntityDataProvider } from 'services/index';
import { getQueryPeoplePickerKey, getQueryTagsKey, getUserByKey, groupTags, IUseTagsQueryData } from 'reactQuery';
import { reactQueryClient } from 'components/ReduxWrappedComponent';
import { IFetchUsersAndGroupTreeResponse } from 'services/RestApiClientFactory';
import { DEFAULT_BREAK_SYMBOL } from 'utils/constants';

export default class ReportEntityDataProvider implements ReportEntityDataProviderInterface {
  private state: unknown;

  constructor() {
    this.state = null;
    window.addEventListener('tasksLoaded', this.#forceReload, false);
    window.addEventListener('tagsLoaded', this.#forceReload, false);
    window.addEventListener('usersLoaded', this.#forceReload, false);
  }

  getItemBreadcrumb(id: string | number, type: ReportEntityType): string {
    this.#loadStateIfEmpty();

    switch (type) {
      case ReportEntityType.TASK: {
        const visitedTaskTreeIds = {};
        let returnString = '';
        const taskData = selectTasks(this.state, {
          field: TaskModelStateFields.TASKS,
        });
        if (taskData) {
          while (id !== 0) {
            const task = taskData[id];
            if (!task) {
              return;
            }

            const breakSymbol = returnString !== '' ? DEFAULT_BREAK_SYMBOL : '';
            returnString = task.name + breakSymbol + returnString;
            id = Number(task.parent_id);

            if (visitedTaskTreeIds[id]) {
              console.warn('Incorrect tasks tree structure for id:', id);
              return;
            }
            visitedTaskTreeIds[id] = true;
          }
        }
        return returnString;
      }

      default: {
        throw new Error(`Not recognized type ${type}`);
      }
    }
  }

  getItemName(id: string | number, type: ReportEntityType): string {
    this.#loadStateIfEmpty();

    switch (type) {
      case ReportEntityType.TASK: {
        const task = selectTaskById(this.state, {
          field: TaskModelStateFields.TASKS,
          id,
        });

        return task.name ?? '';
      }

      case ReportEntityType.TAG: {
        const ids = typeof id === 'number' ? [id] : id.split(',');
        const tags = reactQueryClient.getQueryData(getQueryTagsKey()) as IUseTagsQueryData || [];
        const tagsData = ids.map((id) => tags.find((item) => +item.tagId === id));

        const { tagNames } = groupTags(tagsData);

        return tagNames.join(', ');
      }

      case ReportEntityType.USER: {
        const { users } = reactQueryClient.getQueryData(
          getQueryPeoplePickerKey(),
        ) as IFetchUsersAndGroupTreeResponse;
        const user = getUserByKey(users, id);

        return user?.display_name ?? user?.email ?? '';
      }

      default: {
        throw new Error(`Not recognized type ${type}`);
      }
    }
  }

  #loadStateIfEmpty() {
    if (this.state === null) {
      this.state = store.store.getState();
    }
  }

  #forceReload() {
    reportEntityDataProvider.state = store.store.getState();
  }
}
