import { IGetExpenseAttachmentParams, IGetExpensesParams } from 'services/RestApiClientFactory';

export const expensesDefaultParams: Partial<IGetExpensesParams> = {
  page: 1,
  limit: 20,
  sortOrder: 'desc',
  sortBy: 'madeAt',
  taskPickerStatus: 'all',
};

export const expensesAnyDefaultParams: Partial<IGetExpensesParams> = {
  limit: 2,
};

export const useExpensesQueryKey = (params: Partial<IGetExpensesParams>) => ['KEY_QUERY_EXPENSES', params];

export const useAnyExpensesQueryKey = () => useExpensesQueryKey(expensesAnyDefaultParams);

export const getExpenseCategoriesQueryKey = () => ['KEY_QUERY_EXPENSE_CATEGORIES'];
export const getExpenseAttachmentsQueryKey = (params: IGetExpenseAttachmentParams) => ['KEY_QUERY_EXPENSE_ATTACHMENTS', params];
