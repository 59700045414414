import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';

export class ApiClientAnalytics extends ApiClientBase {
  // save to amplitude
  async lead_save_event_post(data) {
    return await this.fetchData({
      queryString: 'ajax/lead_save_event_post',
      method: 'POST',
      apiVersion: 0,
      additionalHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data,
    });
  }

  // save to table tc_lead_history
  async lead_save_event(queryString) {
    return await this.fetchData({
      queryString: `ajax/lead_save_event/${queryString}`,
      method: 'GET',
      apiVersion: 0,
    });
  }
}
