import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';
import { Dict, IEntries } from 'types';
import { format } from 'date-fns';
import { createQueryString } from 'services/RestApiClientFactory/utils';
import { IEntriesFromWeek, IEntriesGetBody, IGroupDurationChangeResponse } from './ApiClientTimeEntries.types';
import _omit from 'lodash/omit';

export class ApiClientTimeEntries extends ApiClientBase {
  async fetchWeekData(userId, dates, groupBy = ['taskId'], signal = null): Promise<Dict<string> | null> {
    if (!userId || !dates) return null;
    let result: Dict<string> | null = null;

    try {
      const { status, data } = await this.fetchData<Dict<string>>({
        signal,
        queryString: `time-entries/user/${userId}/dateTimeframe/${dates.map(x => format(new Date(x), 'yyyy-MM-dd')).join(',')}/groupBy/${groupBy.join(',')}`,
      });

      if (status === 200) {
        result = data;
      } else {
        throw new Error(result.message ?? result.toString());
      }
    } catch (error) {
      throw new Error(error.message);
    }

    return result;
  }

  async updateWeekTimeEntry({
    entry,
    signal,
    taskDate,
    duration,
    userId,
    tagIds,
  }) {
    if (!entry) return null;

    return await this.fetchData({
      queryString: `time-entries/user/${userId}/group-duration-change`,
      method: 'POST',
      body: {
        ...entry,
        taskDate,
        duration,
        tagIds,
      },
      signal,
    });
  }

  async deleteWeekTimeEntry(signal: AbortSignal, {
    stringObject,
    userId,
    dateFrame,
  }) {
    return await this.fetchData({
      queryString: `time-entries/user/${userId}/group-delete?timeframe=${dateFrame}&${createQueryString(stringObject).slice(1)}`,
      method: 'DELETE',
      signal,
    });
  }

  async get(params: IEntriesGetBody) {
    return await this.fetchData<IEntries>({
      queryString: 'time-entries',
      body: {
        ...params,
        startDate: format(params.startDate, 'yyyy-MM-dd'),
        endDate: format(params.endDate, 'yyyy-MM-dd'),
      },
      method: 'POST',
    });
  }

  async groupDurationChange({ entry }) {
    return await this.fetchData<IGroupDurationChangeResponse>({
      queryString: `time-entries/user/${entry.userId}/group-duration-change`,
      method: 'POST',
      body: _omit({
        ...entry,
        tagIds: entry?.tags && Object.values(entry.tags).flat() || [],
      }, ['userId', 'tags']),
    });
  }

  async getEntriesFromWeek({
    userId,
    dateFrom,
    queryGroupingParams,
  }: {
    userId: number
    dateFrom: string,
    queryGroupingParams: string[]
  }) {
    return await this.fetchData<IEntriesFromWeek>({
      queryString: `time-entries/user/${userId}/dateTimeframe/${dateFrom}/groupBy/${queryGroupingParams.join(',')}`,
    });
  }
}
