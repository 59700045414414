import pickBy from 'lodash/pickBy';
import isUndefined from 'lodash/isUndefined';
import isArray from 'lodash/isArray';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

import { IGetExpensesParams } from './ApiClientExpense.types';
import { convertDateToV3ApiFormat } from 'utils';

export const filterEmptyValuesFromGetExpensesRequest = (requestBody: IGetExpensesParams): IGetExpensesParams => pickBy(requestBody, (value) => !(isUndefined(value) || isNull(value) || (isArray(value) && isEmpty(value)))) as IGetExpensesParams;

export const mapDateValuesForTcDateFormatStrings = (requestBody: IGetExpensesParams) => {
  const mappedBody = {};
  Object.keys(requestBody).forEach((key) => {
    if (requestBody[key] instanceof Date) {
      mappedBody[key] = convertDateToV3ApiFormat(requestBody[key], true);
      return;
    }
    mappedBody[key] = requestBody[key];
  });

  return mappedBody;
};
