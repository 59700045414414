import { ConfigInterface } from 'services/interfaces';
import RestApiClient from './RestApiClient';

function ClientFactory(config: ConfigInterface) {
  return new RestApiClient(config);
}

export default ClientFactory;

export * from './modules';
