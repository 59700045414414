import { fork, takeLatest } from 'redux-saga/effects';
import { ReportModelActions } from './types';
import {
  onFetchGroupedSummaryReport,
  onFetchGroupedSummaryReportSuccess,
  onFetchSummaryReport,
  onSummaryReportSort,
  refreshReport as refreshSummaryReport,
} from './workers/SummaryReport';
import {
  onDeleteEntry,
  onDeleteEntrySuccess,
  onFetchDetailedReport,
  refreshReport as refreshDetailedReport,
} from './workers/DetailedReport';
import { EntryActions } from '../EntryModel/types';

export const ReportModelSagas = [
  function* () {
    yield fork(function* () {
      yield takeLatest(
        ReportModelActions.INIT_SUMMARY_REPORT,
        onFetchSummaryReport,
      );
    });
    yield fork(function* () {
      yield takeLatest(
        ReportModelActions.FETCH_GROUPED_SUMMARY_REPORT,
        onFetchGroupedSummaryReport,
      );
    });
    yield fork(function* () {
      yield takeLatest(
        ReportModelActions.FETCH_GROUPED_SUMMARY_REPORT_SUCCESS,
        onFetchGroupedSummaryReportSuccess,
      );
    });
    yield fork(function* () {
      yield takeLatest(
        ReportModelActions.SUMMARY_REPORT_DATA_SORT,
        onSummaryReportSort,
      );
    });
    yield fork(function* () {
      yield takeLatest(
        ReportModelActions.INIT_DETAILED_REPORT,
        onFetchDetailedReport,
      );
    });
    yield fork(function* () {
      yield takeLatest(
        EntryActions.DELETE_ENTRY_SUCCESS,
        onDeleteEntrySuccess,
      );
    });
    yield fork(function* () {
      yield takeLatest(
        EntryActions.DELETE_ENTRY,
        onDeleteEntry,
      );
    });
    yield fork(function* () {
      yield takeLatest(
        ReportModelActions.REFRESH_SUMMARY_REPORT,
        refreshSummaryReport,
      );
    });
    yield fork(function* () {
      yield takeLatest(
        ReportModelActions.REFRESH_DETAILED_REPORT,
        refreshDetailedReport,
      );
    });
  },
];
