import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';

import {
  ChangeEstimateParams,
  CheckCardStatusParams,
  JoinOrganisationParams,
  RefreshSectionParams,
  RefreshSectionResponse,
} from './ApiClientTrelloPowerUp.types';
import { createApiStringFromObject } from 'services/RestApiClientFactory/utils';
import {
  ApiErrorTextHandler,
} from 'services/RestApiClientFactory/ApiErrorHandler/ApiErrorTextHandler/ApiErrorTextHandler';
import { ApiClientTimer } from 'services/RestApiClientFactory/modules/ApiClientTimer';
import {
  ApiClientTimerGetParams,
  ApiClientTimerPostParams,
} from 'services/RestApiClientFactory/modules/ApiClientTimer/ApiClientTimer.types';

import { TrelloApiKeyStorage } from 'trelloPowerUp/services/TrelloStorage/TrelloApiKeyStorage/TrelloApiKeyStorage';
import { ApiClientEntries } from 'services/RestApiClientFactory/modules/ApiClientEntries';
import { AddEntryParams } from 'services/RestApiClientFactory/modules/ApiClientEntries/ApiClientEntries.types';
import { ApiClientAuth } from 'services/RestApiClientFactory/modules/ApiClientAuth';
import { LoginParams } from 'services/RestApiClientFactory/modules/ApiClientAuth/ApiClientAuth.types';

export class ApiClientTrelloPowerUp extends ApiClientBase {
  TrelloApiKeyStorage = new TrelloApiKeyStorage();

  async fetchApiToken() {
    return this.TrelloApiKeyStorage.get();
  }

  async timerPost(params: ApiClientTimerPostParams) {
    const apiToken = await this.fetchApiToken();

    return await new ApiClientTimer({
      baseUrl: this.config.baseUrl,
      apiToken,
    }).post(params);
  }

  async timerGet(params: ApiClientTimerGetParams) {
    const apiToken = await this.fetchApiToken();

    return await new ApiClientTimer({
      baseUrl: this.config.baseUrl,
      apiToken,
    }).get(params);
  }

  async checkCardStatus({ context, taskId }: CheckCardStatusParams) {
    const { data, ok } = await this.fetchData({
      queryString: `third_party/trellopowerup/check_card_status/${taskId}/${context}`,
      apiVersion: 0,
      oldTextApi: true,
    });

    if (!ok) {
      throw new ApiErrorTextHandler('', { ok, text: data as string });
    }

    return data;
  }

  async getToken() {
    return await this.fetchData<string | '0'>({
      queryString: 'third_party/trellopowerup/get_api_key',
      apiVersion: 0,
      oldTextApi: true,
    });
  }

  async refreshSection({ reportVersion, taskId, boardId }: RefreshSectionParams) {
    const { ok, data } = await this.fetchData<string>({
      queryString: `third_party/trellopowerup/section_${reportVersion}_report/${taskId}/${boardId}`,
      apiVersion: 0,
      oldTextApi: true,
    });

    if (!ok) {
      throw new ApiErrorTextHandler('', { ok, text: data });
    }

    return JSON.parse(data) as RefreshSectionResponse;
  }

  async changeEstimate({ taskId, context, estimated }: ChangeEstimateParams) {
    const { data, ok } = await this.fetchData<RefreshSectionResponse>({
      queryString: `third_party/trellopowerup/change_estimate/${taskId}/${context.board}`,
      apiVersion: 0,
      method: 'POST',
      body: createApiStringFromObject({ estimated }),
      additionalHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      oldTextApi: true,
    });

    if (!ok) {
      throw new ApiErrorTextHandler('', { ok, text: data as unknown as string });
    }

    return data;
  }

  async joinOrganisation({ id }: JoinOrganisationParams) {
    const { data, ok } = await this.fetchData({
      queryString: 'third_party/trellopowerup/join_organisation',
      apiVersion: 0,
      method: 'POST',
      body: createApiStringFromObject({
        id,
      }),
      additionalHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      oldTextApi: true,
    });

    if (!ok) {
      throw new ApiErrorTextHandler('', { ok, text: data as string });
    }

    return data;
  }

  async addEntry(signal: AbortSignal, params: AddEntryParams) {
    const apiToken = await this.fetchApiToken();

    return await new ApiClientEntries({
      baseUrl: this.config.baseUrl,
      apiToken,
    }).addEntry(signal, params);
  }

  async token(params: LoginParams) {
    const apiToken = await this.fetchApiToken();

    return await new ApiClientAuth({
      baseUrl: this.config.baseUrl,
      apiToken,
    }).token(params);
  }

  async logout() {
    const apiToken = await this.fetchApiToken();

    return await new ApiClientAuth({
      baseUrl: this.config.baseUrl,
      apiToken,
    }).logout();
  }
}
