import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';

export class ApiClientCancelData extends ApiClientBase {
  async cancelData(signal: AbortSignal) {
    type CancelDataResponse = {
      success?: {
        numberOfCreatedProjects: number;
        numberOfVisits: string,
        totalTrackedTime: number,
        userFirstName: string
      }
    };

    const { data } = await this.fetchData<CancelDataResponse>({
      apiVersion: 1,
      queryString: 'group_cancel_data',
      signal,
    });

    return data;
  }
}
