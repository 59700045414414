import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';
import { CheckActionParams, CheckActionResponse } from './ApiClientGuard.types';

export class ApiClientGuard extends ApiClientBase {
  async checkAction({ action, context }: CheckActionParams) {
    const { data, status } = await this.fetchData<CheckActionResponse>({
      apiVersion: 1,
      queryString: `guard/action/${action}/context/${context}`,
    });

    if (status !== 200) {
      throw new Error(`${status}`);
    }

    return data;
  }
}
