import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';
import { TaskPickerItem } from 'services/RestApiClientFactory/modules/ApiClientTasks/ApiClientTasks.types';

export class ApiClientFavorites extends ApiClientBase {
  async taskPickerFavorites() {
    const { data } = await this.fetchData<{data: TaskPickerItem[]}>({
      queryString: 'taskPicker/favourites',
      method: 'GET',
    });

    return data.data;
  }

  async taskPickerFavoritesAdd({ taskId }: { taskId: number }) {
    const { data } = await this.fetchData<number[]>({
      queryString: `taskPicker/favourites/add/${taskId}`,
      method: 'POST',
    });

    return data;
  }

  async taskPickerFavoritesRemove({ taskId }: { taskId: number }) {
    const { data } = await this.fetchData<number[]>({
      queryString: `taskPicker/favourites/delete/${taskId}`,
      method: 'DELETE',
    });

    return data;
  }
}
