import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';
import { Dict } from 'types';

export class ApiClientGroup extends ApiClientBase {
  async fetchRate(group: number) {
    if (!group) return null;

    const { data } = await this.fetchData<Dict<string>>({
      queryString: `group/${group}/rate`,
      apiVersion: 1,
    });

    return data;
  }
}
