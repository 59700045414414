import { action } from 'typesafe-actions';

import { INewTaskBody } from 'types';

import { TaskModelActions } from './types';

export const fetchTasks = (data) => action(TaskModelActions.FETCH_TASKS, data);

export const fetchTasksSuccess = (data) => action(TaskModelActions.FETCH_TASKS_SUCCESS, data);

export const fetchTasksPickerSuccess = (data) => action(TaskModelActions.FETCH_TASKS_PICKER_SUCCESS, data);

export const changeLoadingTasksStatus = (status) => action(TaskModelActions.CHANGE_LOADING_TASKS_STATUS, { status });

export const checkTasksCohesion = () => action(TaskModelActions.CHECK_TASKS_COHESION);

export const fetchMissingTasks = (taskIds) => action(TaskModelActions.FETCH_MISSING_TASKS, { taskIds });

export const expandTaskList = (tasks) => action(TaskModelActions.EXPAND_TASK_LIST, { tasks });

export const createNewTask = (task: INewTaskBody) => action(TaskModelActions.CREATE_NEW_TASK, task);

export const updateNewTaskLoading = (status: boolean) => action(TaskModelActions.UPDATE_NEW_TASK_LOADING, status);

export const updateNewTaskError = (error: string) => action(TaskModelActions.UPDATE_NEW_TASK_ERROR, error);

export const updateNewTaskCreated = (data: { task: any, type: 'add' | 'remove' }) => action(TaskModelActions.UPDATE_NEW_TASK_CREATED, data);
