import { createElement, ReactElement } from 'react';
import { createRoot, Root } from 'react-dom/client';
import { ErrorBoundary } from '@sentry/react';

import { ReactWebComponentWrapper } from 'components/ReactWebComponent';

import { convertHTMLAttributesToReactProps, getResourceFileUrlByTemplate } from 'utils';

import 'styles/tailwind.css';
import 'styles/scrollbar.scss';
import './ReactWebComponent.styles.scss';

export class ReactWebComponent {
  renderComponent: (attrs: Record<string, string>) => ReactElement;
  cssUrl: string;
  name: string;
  attributesToObserve: string[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(name: string, renderComponent: (attrs: Record<string, any>) => ReactElement, cssUrl: string, attributesToObserve?: string[]) {
    this.renderComponent = renderComponent;
    this.cssUrl = cssUrl;
    this.name = name;
    this.attributesToObserve = attributesToObserve;
    this.mount();
  }

  private mount() {
    const { attributesToObserve, cssUrl, renderComponent } = this;

    const reactWebComponentPrototype = class extends HTMLElement {
      cssLoaded: boolean;
      attrs: Record<string, string>;
      root: Root;

      constructor() {
        super();
        const mountPoint = document.createElement('span');
        this.root = createRoot(mountPoint);
        this.cssLoaded = false;
        this.attachShadow({ mode: 'open' }).appendChild(mountPoint);
      }

      static get observedAttributes() {
        return attributesToObserve;
      }

      create(attrs: { [key: string]: string }) {
        this.root.render(
          <ErrorBoundary>
            <link
              rel='stylesheet'
              href={`${getResourceFileUrlByTemplate(cssUrl, 'css')}`}
              onLoad={() => {
                this.cssLoaded = true;
                this.create(attrs);
              }}
            />
            {
              this.cssLoaded && (
                createElement(ReactWebComponentWrapper, {
                  inner: createElement(renderComponent, attrs),
                })
              )
            }
          </ErrorBoundary>,
        );
      }

      createPropsFromAttributes() {
        return convertHTMLAttributesToReactProps(this);
      }

      attributeChangedCallback() {
        this.create(this.createPropsFromAttributes());
      }

      connectedCallback() {
        this.create(this.createPropsFromAttributes());
      }
    };

    customElements.define(this.name, reactWebComponentPrototype);
  }
}
