import { fromJS } from 'immutable';
import get from 'lodash/get';
import { Reducer } from 'redux';
import { ReportModelActions, ReportModelState, ReportModelStateFields } from './types';
import { PayloadAction } from 'types/reduxTypes';

export const initialState: ReportModelState = fromJS({
  [ReportModelStateFields.REPORT_SUMMARY]: null,
  [ReportModelStateFields.REPORT_FILE_STATUS]: null,
});

const reportModelReducer: Reducer<ReportModelState, PayloadAction> = (
  state: ReportModelState = initialState,
  action: PayloadAction = {} as PayloadAction,
): ReportModelState => {
  switch (action.type) {
    case ReportModelActions.INIT_SUMMARY_REPORT: {
      return state;
    }

    case ReportModelActions.SAVE_SUMMARY_REPORT_DATA:
    case ReportModelActions.FETCH_GROUPED_SUMMARY_REPORT_SUCCESS: {
      const data = get(action, ['payload', 'reportData']);
      return state.setIn([ReportModelStateFields.REPORT_SUMMARY], {
        ...data,
      });
    }

    case ReportModelActions.FETCH_GROUPED_SUMMARY_REPORT_META_SUCCESS: {
      const data = get(action, ['payload', 'metaData']);
      return state.setIn([ReportModelStateFields.REPORT_META], {
        ...data,
      });
    }

    case ReportModelActions.SAVE_SUMMARY_REPORT_ID: {
      const reportId = get(action, ['payload']);

      return state.setIn([ReportModelStateFields.SUMMARY_REPORT_ID], reportId);
    }

    case ReportModelActions.SET_REPORT_STATUS_RESPONSE: {
      const status = get(action, ['payload']);
      return state.setIn([ReportModelStateFields.REPORT_STATUS_RESPONSE], status);
    }

    case ReportModelActions.SET_REPORT_FILE_STATUS: {
      const status = get(action, ['payload']);
      return state.setIn([ReportModelStateFields.REPORT_FILE_STATUS], status);
    }

    case ReportModelActions.FETCH_REPORT_STATUS_UPDATED: {
      const status = get(action, ['payload']);

      return state.setIn([ReportModelStateFields.REPORT_STATUS], status);
    }

    case ReportModelActions.DATA_IS_LOADING_UPDATED: {
      const status = get(action, ['payload']);

      return state.setIn(
        [ReportModelStateFields.REPORT_DATA_IS_LOADING],
        status,
      );
    }

    case ReportModelActions.INIT_DETAILED_REPORT: {
      return state;
    }

    case ReportModelActions.SAVE_DETAILED_REPORT_ID: {
      const reportId = get(action, ['payload']);

      return state.setIn([ReportModelStateFields.DETAILED_REPORT_ID], reportId);
    }

    case ReportModelActions.FETCH_DETAILED_REPORT_SUCCESS: {
      const data = get(action, ['payload', 'reportData']);
      return state.setIn([ReportModelStateFields.REPORT_DETAILED], {
        ...data,
      });
    }

    case ReportModelActions.DETAILED_REPORT_DATA_SORT: {
      return state;
    }

    case ReportModelActions.SUMMARY_REPORT_DATA_SORT: {
      return state;
    }

    case ReportModelActions.DETAILED_REPORT_COLUMN_SIZING: {
      const sizing = get(action, ['payload', 'sizing']);

      return state.setIn([ReportModelStateFields.REPORT_DETAILED_COLUMN_SIZING], sizing);
    }

    default: {
      return state;
    }
  }
};

export default reportModelReducer;
