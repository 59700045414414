import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';
import { AddSurveyAnswerParams, AddSurveyResponse } from 'services/RestApiClientFactory';

export class ApiClientSurvey extends ApiClientBase {
  async addSurveyAnswer(params: AddSurveyAnswerParams) {
    const { data } = await this.fetchData<AddSurveyResponse>({
      queryString: 'survey/answers',
      apiVersion: 3,
      method: 'POST',
      body: params,
    });

    return data;
  }
}
