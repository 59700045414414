import { IFetchUsersAndGroupTreeResponse, IPeoplePickerParams } from 'services/RestApiClientFactory';

export const getUserByKey = (users: IFetchUsersAndGroupTreeResponse['users'], userId: string | number) => users[`u${userId}`];

export const getDefaultUserValue = () => ({
  user_id: '',
  email: 'No user',
  display_name: '',
});

export const defaultFetchUsersAndGroupTreeParams: IPeoplePickerParams = {
  filters: {
    status: 'all',
  },
  options: {
    limitByOrganizationStructure: 0,
    hideGuests: 1,
  },
};
