import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';
import { ISetSessionFiltersParams } from 'services/RestApiClientFactory';

export class ApiClientSession extends ApiClientBase {
  async setSessionFilters({ start_time, end_time, users }: ISetSessionFiltersParams) {
    try {
      const formData = new FormData();
      formData.append('start_time', start_time);
      formData.append('end_time', end_time);

      if (users) {
        users.forEach((user) => {
          formData.append('users[]', user);
        });
      }

      return await this.fetchData({
        queryString: 'ajax/set_session_filters',
        apiVersion: 0,
        method: 'POST',
        body: formData,
        additionalHeaders: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
    } catch (error) {
      throw new Error(error);
    }
  }
}
