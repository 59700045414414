import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';
import { createApiStringFromObject, createQueryString } from 'services/RestApiClientFactory/utils';
import { format } from 'date-fns';
import {
  ApiClientApprovalsResponse,
} from 'services/RestApiClientFactory/modules/ApiClientApprovals/ApiClientApprovals.types';

export class ApiClientApprovals extends ApiClientBase {
  async fetchActionApprovals(signal: AbortSignal, data) {
    return await this.fetchData({
      queryString: 'approval',
      method: 'POST',
      apiVersion: 1,
      additionalHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: createApiStringFromObject(data),
      signal,
    });
  }

  async fetchApprovals(signal: AbortSignal, from: Date, to: Date, all = 1, withAttendance = 1) {
    const queryStringParams = createQueryString({
      from: format(from, 'yyyy-MM-dd'),
      to: format(to, 'yyyy-MM-dd'),
      all,
      withAttendance,
    });

    return await this.fetchData<ApiClientApprovalsResponse>({
      queryString: `approval${queryStringParams}`,
      apiVersion: 1,
      signal,
    });
  }
}
