import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';

import { LoginParams } from './ApiClientAuth.types';
import {
  ApiErrorTextHandler,
} from 'services/RestApiClientFactory/ApiErrorHandler/ApiErrorTextHandler/ApiErrorTextHandler';
import { createApiStringFromObject } from 'services/RestApiClientFactory/utils';

export class ApiClientAuth extends ApiClientBase {
  async fetchApiToken() {
    return this.config.apiToken || super.fetchApiToken();
  }

  async logout() {
    return await this.fetchData({
      queryString: 'auth/logout/0',
      apiVersion: 0,
    });
  }

  async login(params: LoginParams) {
    return await this.fetchData<string>({
      queryString: 'auth/login',
      apiVersion: 0,
      method: 'POST',
      body: params,
    });
  }

  async token(params: LoginParams) {
    const response = await this.fetchData<string>({
      queryString: 'auth/token',
      apiVersion: 0,
      method: 'POST',
      body: createApiStringFromObject(params),
      oldTextApi: true,
      additionalHeaders: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (!response.ok) {
      throw new ApiErrorTextHandler('', {
        text: response.data,
        ok: response.ok,
      });
    }

    return response;
  }
}
