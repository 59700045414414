import BrowserStorageServiceInterface from './interface';
import { Maybe } from 'types';

export default class IndexedDBService
implements BrowserStorageServiceInterface {
  getItem<T>(key: string): Promise<Maybe<T>> {
    return new Promise((resolve) => {
      // eslint-disable-next-line
      // @ts-ignore
      const valueLoading = window.indexedDb.get(key);
      valueLoading.then((result) => {
        resolve(result);
      });
    });
  }

  setItem<T>(key: string, item: T): Promise<Maybe<T>> {
    return new Promise((resolve) => {
      // eslint-disable-next-line
      //@ts-ignore
      resolve(window.indexedDb.set(key, item));
    });
  }

  deleteItem(key: string,
  ): Promise<Maybe<Event>> {
    return new Promise((resolve) => {
      // eslint-disable-next-line
      //@ts-ignore
      resolve(window.indexedDb.set(key, undefined));
    });
  }
}
