import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';
import { Dict } from 'types';

export class ApiClientIntegrations extends ApiClientBase {
  async synchIntegrations(signal: AbortSignal) {
    let result: Dict<string> | null = null;

    try {
      const { data, status } = await this.fetchData<Dict<string>>({
        signal,
        queryString: 'ajax/synch_integrations',
        apiVersion: 0,
      });

      if (status === 200) {
        result = data;
      } else {
        throw new Error(result.message ?? `${result}`);
      }

    } catch (error) {
      console.error(error);
      throw new Error(error.message);
    }

    return result;
  }

  async hasIntegration() {
    return await this.fetchData({
      queryString: 'ajax/has_integrations',
      apiVersion: 0,
    });
  }
}
