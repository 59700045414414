import { ApiClientBase } from 'services/RestApiClientFactory/modules/ApiClientBase';
import {
  CustomReportBySlug,
  CustomReportBySlugResponse,
} from './ApiClientCustomReport.types';

export class ApiClientCustomReport extends ApiClientBase {
  async getCustomReport(signal: AbortSignal, requestParams: CustomReportBySlug) {
    let result: CustomReportBySlugResponse;
    const { slug } = requestParams;
    try {
      const { data, status } = await this.fetchData<CustomReportBySlugResponse>({
        signal,
        queryString: `custom-report/${slug}`,
        method: 'GET',
        apiVersion: 3,
      });

      result = data;

      if (status === 204 || status === 200) {
        return result;
      }
    } catch (error) {
      console.error(error);
    }

    return result;
  }
}
